import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'
import axios from 'axios';
import Reaptcha from 'reaptcha';

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            erroraftersubmit: false,
            show: false,
            submitting: false,
            submitted: false,
            success: false,
            status: null,
            buttontext: "Send Message",
            verified: false
        }
        this.show = this.show.bind(this)
    }
    static contextType = ThemeContext

    onVerify = recaptchaResponse => {
        this.setState({
          verified: true
        });
      };    

    show() {
      this.setState({show : true})
    }

    check(val) {
        if (this.state.error && val === "") {
                return false
        } else {
            return true
        }
    }

    handleServerResponse = (ok, msg, form) => {
            this.setState({submitting: false}) 
            this.setState({status: { ok, msg }});
        if (ok) {
            this.setState({buttontext: msg});
            this.setState({success: true});
            form.reset();
        }
        else{
            this.setState({erroraftersubmit: true})
            this.setState({buttontext: msg});
        }
      };

    handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        this.setState({submitting: true});

        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.setState({error: true})   
            return false;   
        } else {
            this.setState({error: false})
            axios({
                url: 'https://formspree.io/f/mzbkkyrb',
                method: 'post',
                headers: {
                  'Accept': 'application/json'
                },
                data: formData
              })
              .then(r => {
                this.handleServerResponse(true, "Thanks", form);
              })
              .catch(r => {
                this.handleServerResponse(false, "Failed", form);
              });
            return false;
        }
    }
    render() {
        return (
            <section id={`${this.props.id}`} className="contact" style={{height: this.context.height}}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                        </h2>
                    </Col>
                    <Col md={5} className="form">
                        {this.form()}
                    </Col>
                    <Col md={5} className="map">
                        {this.map()}
                    </Col>
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                <div className="form-container">
                    <div className="line-text">
                        <h4>Get In Touch</h4>
                        <form id="contactform" onSubmit={this.handleOnSubmit}>
                            <AnimationContainer delay={50} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" name="name" className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="Name" onChange={e => this.setState({name: e.target.value})} />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={100} animation="fadeInUp fast">
                            <div className="form-group">
                                <input type="email" name="_replyto" className={`email ${this.check(this.state.email) ? "" : "error"}`} placeholder="Email" onChange={e => this.setState({email: e.target.value})} />
                            </div>
                            </AnimationContainer>
                            <AnimationContainer delay={150} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" name="phone" className={`phone ${this.check(this.state.email) ? "" : "error"}`} placeholder="Phone" onChange={e => this.setState({phone: e.target.value})} />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={200} animation="fadeInUp fast">
                            <div className="form-group">
                                <textarea name="message" className={`message ${this.check(this.state.message) ? "" : "error"}`} placeholder="Message" onChange={e => this.setState({message: e.target.value})}></textarea>
                            </div>
                            </AnimationContainer>
                            <AnimationContainer delay={250} animation="fadeInUp fast">
                                <Reaptcha sitekey="6LdMfBoaAAAAANshNkU7FWDUssaVUXrsmehLqNdZ" onVerify={this.onVerify} />
                            </AnimationContainer>
                            <AnimationContainer delay={300} animation="fadeInUp fast">
                            <div className="submit">
                                <button type="submit" disabled={this.state.submitting || !this.state.verified} className={`hover-button ${this.state.error || this.state.erroraftersubmit? "error" : (this.state.success? "success" : "")}`} value="Send">
                                    <span id="buttonText">{this.state.buttontext}</span>
                                </button>
                            </div>
                            </AnimationContainer>
                        </form>
                    </div>
                </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>
                    <iframe title="map" width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=27H%20Niasstraat%2C%20Amsterdam%2C%20Netherlands+(Eversoftware)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"/>
                </AnimationContainer>
            )
        }
    }

}

export default Contact