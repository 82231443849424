import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import BaffleText from 'components/baffle-text'
import AnimationContainer from 'components/animation-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faAngular, faMicrosoft, faNodeJs, faGoogle} from '@fortawesome/free-brands-svg-icons'
import {
  faLightbulb,
  faSmileBeam,
  faPizzaSlice,
  faQuoteRight,
  faCode,
} from '@fortawesome/free-solid-svg-icons'
import Counter from 'components/counter'
import ThemeContext from '../../context'
import './styles.scss'

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.show = this.show.bind(this)
  }

  static contextType = ThemeContext

  show() {
    this.setState({ show: true })
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              this.context.height !== 'auto'
                ? this.context.height * 0.8
                : 'inherit',
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>Services</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="What I Do"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    this.context.height !== 'auto'
                      ? this.context.height * 0.6
                      : 'inherit',
                }}
              >
                <Container>{this.services()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="bottom">{this.counters()}</Row>
      </section>
    )
  }

  services() {
    if (this.state.show || this.context.height === 'auto') {
      return (
        <Row>
          <Col md={4} className="service">
            <AnimationContainer delay={200} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faReact} />
              </div>
              <h4>Front-end React</h4>
              <p>
                Ranging from Single-Page-Apps, Server-Side-Rendered to Static sites, I have worked on several projects using React and Gatsby professionally as well as in side projects from home, for example: the very site your looking at right now!
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={400} animation="fadeInDown fast">
              <div className="icon">
                <FontAwesomeIcon icon={faAngular} />
              </div>
              <h4>Angular Web App</h4>
              <p>
                My experience with Angular and AngularJS include working on the main public website for Ajax (the soccer club) and building a dynamic pubquizz application for in-house use at XPRTZ as well as some side projects.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={600} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faMicrosoft} />
              </div>
              <h4>Microsoft Azure</h4>
              <p>
                Many projects I have worked on were based on the Microsoft stack, such as message oriented services at ABN Hypotheken using Azure Servicebus, serverless microservices using Azure Functions for Ajax, Azure AD Single-Sign-On for a backoffice CMS application and CI/CD pipelines using Azure DevOps.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={800} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faNodeJs} className="solid" />
              </div>
              <h4>NodeJS</h4>
              <p>
                I love working in Node. One of the major benefits in my opinion, is that having a backend language which is capable of functional as well as object oriented programming paradigms lets you solve domain problems much more efficiently than more restrictive languages.
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={1000} animation="fadeInUp fast">
              <div className="icon">
                <FontAwesomeIcon icon={faGoogle} className="solid" />
              </div>
              <h4>Google Cloud</h4>
              <p>
                Google Cloud is fairly new to me, but I'm really enjoying this stack. For my current client, DPG, my team is using many services of Google Cloud, including Google Kubernetes Engine. Sidenote: the site your currently watching is hosted on Google Cloud!
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={1200} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faLightbulb} className="solid" />
              </div>
              <h4>Domain Driven Design</h4>
              <p>
                Having worked at Movares as technical lead in a scrum team making design software for civil engineers, I have learned a lot about the ins and outs of DDD and CQRS. Not only on an abstract level, but about when and how to actually do it, what the pitfalls are, and how make it work.
              </p>
            </AnimationContainer>
          </Col>
        </Row>
      )
    }
  }

  counters() {
    if (this.state.show || this.context.height === 'auto') {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faSmileBeam}
                value={8}
                text="Happy Clients & Employers"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faQuoteRight}
                value={getCurrentYearsAsDeveloper()*186}
                text="Pullrequests Reviewed"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faPizzaSlice}
                value={getCurrentYearsAsDeveloper()*52}
                text="Pizzas Ordered"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCode}
                value={getCurrentYearsAsDeveloper()*8153}
                text="Lines of Code"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
        </Container>
      )
    }
  }
}

const getCurrentYearsAsDeveloper = () => (new Date()-new Date(2016,5,1,0,0,0,0))/(1000*60*60*24*365.25)

export default Services
