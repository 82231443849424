import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faLeanpub } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>Get the job done</h3>
                            <div className="separator" />
                            <p>I see myself as a goal oriented, driven, lifelong learner. I am always looking to take on new challenges, learn about new tech and constantly want to develop.</p>
                            <p>I got my private pilot license at 17, programmed onboard navigational software for satellites for Airbus at 26, and am now focussed on taking on the exciting world of web and mobile app development.</p>
                            <p>In the context of a team, I make an effort to drive and motivate others. In situations of independent work, I love to solve complex problems and am committed to really deliver business value in the projects I work on.</p>
                            <p>Apart from my passion as developer I like to spend my free time making electronic music in my music studio, digging for new tracks to play when DJing, going for a run in the park and most of all; spending time with friends in Amsterdam. Other than that I like to travel and love doing adventurous trips round the world.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://www.github.com/maikevers')}/>
                                <FontAwesomeIcon icon={faLeanpub} className="social_icon" onClick={() => window.open('https://app.pluralsight.com/profile/maikevers')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/maikevers/')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Javascript, Typescript" value={90} delay={1100} />
                                <Progress name="Kotlin" value={50} delay={1100} />
                                <Progress name="C# .NET" value={60} delay={1100} />
                                <Progress name="Angular, React & Vue" value={50} delay={1100} />
                                <Progress name="Domain Driven Design & Test Driven Development" value={90} delay={1100} />
                                <Progress name="CQRS & Event Sourcing" value={60} delay={1100} />
                                <Progress name="Docker & Kubernetes" value={60} delay={1100} />
                                <Progress name="Azure & Azure DevOps" value={70} delay={1100} />
                                <Progress name="Google Cloud" value={40} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero