import React from "react";
import "./styles.scss";
import { StaticQuery, graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Glitch from "components/glitch";
import Typewriter from "typewriter-effect";
import ThemeContext from "../../context";
import {faArrowDown} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var scrollToElement = require("scroll-to-element");

class Hero extends React.Component {
  static contextType = ThemeContext;

  render() {
    return (
      <div>
      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Hello, I'm</h4>
              </div>
              <Glitch text="Maik Evers" />
              <Typewriter
                options={{
                  strings: [
                    "Full Stack Developer",
                    "Google Cloud Engineer",
                    "Lifelong Learner",
                    "Mobile App Developer",
                    "Azure Engineer",
                    "Agile Teamplayer",
                    "Kotlin Enthousiast",
                    "Functional Programmer",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <a href="https://www.linkedin.com/in/maikevers">
                <button className="hover-button">
                  <span>Connect</span>
                </button>
              </a>
            </div>
            {/* {this.icons()} */}
          </Col>
          <Col>
            <button className='scrollbutton' onClick={() => this.navScroll('about', 1)}>
              <FontAwesomeIcon className='scrollbuttonicon' icon={faArrowDown}/>
            </button>
          </Col>
          <Col md={6} className="img">
            <img
              src={this.props.mainImg.childImageSharp.fluid.src}
              alt="person"
            />
          </Col>
        </Row>
      </section>
      </div>
    );
  }

  navScroll(id, v) {
    this.setState({ show: false });
    const el = document.getElementById(id);
    scrollToElement(el, {
      offset: 0,
      ease: "in-out-expo",
      duration: 2000,
    });
  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <img
          src={value.node.childImageSharp.fluid.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 10) % 2 === 0 ? "up" : "down"
          } float-image`}
          style={{
            left: `${index * 10}%`,
            bottom: `${
              Math.random() *
                (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
              +(index % 2 === 0 ? 70 : 10)
            }%`,
          }}
          alt="shape"
          key={index}
        />
      );
    });
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "person.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
);
